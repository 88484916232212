import React, { useEffect } from "react";
import { Header, Table, Button, Modal, Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import {
  getDrinks,
  toggleDrinkModal,
  deleteDrink,
  editDrink,
  editDrinkModal,
  getDrinksForDatabase,
  editDrinkForDatabase,
  deleteDrinkForDatabase,
} from "../../redux/drinks/drinks.actions";

// Form Constants
import ComposeDrink from "./composeDrink";
import EditDrink from "./editDrink";
import { useState } from "react";

const databaseOptions = [
  { key: "dev", value: "dev_drinks", text: "Global" },
  { key: "nl", value: "netherlands_drinks", text: "Netherlands" },
];

const DrinksComponent = ({ version }) => {
  const dispatch = useDispatch();
  const { drinks } = useSelector((state) => state);

  const [database, setDatabase] = useState("dev_drinks");

  const { loading, error } = drinks;

  const onDeleteDrink = (id) => {
    dispatch(deleteDrinkForDatabase(id, database));
  };

  const onEdit = (drink) => {
    dispatch(editDrinkModal(drink));
  };
  const editStatus = (value) => {
    dispatch(
      editDrinkForDatabase(database, value._id, { status: !value.status }),
    );
  };

  useEffect(() => {
    dispatch(getDrinksForDatabase(database));
  }, [dispatch, database]);

  if (error) {
    toast.error(error);
  }

  let renderCartridgeType;

  if (drinks.drinkList && drinks.drinkList.length) {
    renderCartridgeType = drinks.drinkList.map((value) => {
      let ingredientsContained;

      if (value.ingredients && value.ingredients.length) {
        ingredientsContained = value.ingredients.map((ingredient) => {
          return `${ingredient.name}, `;
        });
      } else {
        ingredientsContained = "None";
      }

      return (
        <Table.Row key={value._id}>
          <Table.Cell>
            <Header as="h4" image>
              <Header.Content>{value.name}</Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell>{ingredientsContained}</Table.Cell>
          <Table.Cell>{value.updatedAt}</Table.Cell>
          <Table.Cell>{value.status}</Table.Cell>
          <Table.Cell>
            <Button
              toggle
              active={value.status}
              onClick={() => editStatus(value)}
            >
              {value.status ? "Active" : "TEST"}
            </Button>
          </Table.Cell>
          <Table.Cell>
            <ActionButtonGroup
              id={value._id}
              onDelete={onDeleteDrink}
              onEdit={() => onEdit(value)}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  } else {
    renderCartridgeType = (
      <Table.Row>
        <Table.Cell>No cartridge types found.</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <DashboardContentLayout
      title="H2YO Drinks"
      loading={loading}
      openAddModal={() => dispatch(toggleDrinkModal())}
    >
      <Dropdown
        onChange={(_, data) => setDatabase(data.value)}
        placeholder="Select database"
        defaultValue={databaseOptions[0].value}
	  id="database-dropdown"
        fluid
        search
        selection
        options={databaseOptions}
      />
      <Modal
        style={{
          width: "90%",
        }}
        size={"large"}
        open={drinks.drinkModal}
        onClose={() => dispatch(toggleDrinkModal())}
      >
              <ComposeDrink database={database} version={version} />
      </Modal>
      <Modal
        style={{
          width: "90%",
        }}
        size={"large"}
        open={drinks.editModal}
        onClose={() => dispatch(editDrinkModal())}
      >
        <EditDrink />
      </Modal>
      <Table basic="very" celled collapsing size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Contents</Table.HeaderCell>
            <Table.HeaderCell>Last Updated</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderCartridgeType}</Table.Body>
      </Table>
    </DashboardContentLayout>
  );
};

export default DrinksComponent;
