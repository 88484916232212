import types from "./types";

const initialState = {
  drinkList: [],
  loading: null,
  error: null,
  success: null,
  drinkModal: false,
  editModal: false,
  secondaryModal: false,
  database: null,
};

const cartridgeTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_DRINKS:
      return {
        ...state,
        drinkList: action.payload,
      };
    case types.DRINKS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case types.DRINKS_LOADING_SUCCESS:
      return {
        ...state,
        success: true,
        loading: null,
        error: null,
      };
    case types.DRINKS_LOADING_ERROR:
      return {
        ...state,
        loading: null,
        error: action.payload,
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        loading: null,
        error: null,
      };
    case types.TOGGLE_DRINK_MODAL:
      return {
        ...state,
        drinkModal: !state.drinkModal,
      };
    case types.EDIT_DRINK_MODAL:
      return {
        ...state,
        editModal: !state.editModal,
        editingDrink: action.payload,
        database: action.database,
      };
    case types.SECONDARY_DRINK_MODAL:
      return {
        ...state,
        secondaryModal: !state.secondaryModal,
      };
    default:
      return state;
  }
};

export default cartridgeTypeReducer;
