import React, { useState, useEffect } from 'react';
import { Table, Header, Checkbox, Dropdown } from 'semantic-ui-react';

// Intensity formulae from ms to ml is Ts * 0.62

const EditedDrinkIngredient = ({
  orderDrinkItemsByIndex,
  index,
  value,
  setDrinkItems,
  removeFromDrink,
  mode,
  hw,
}) => {
  const [state, setState] = useState(
    {
      ...value,
      isBoost: value.isBoost || false,
      inDrink: value.inDrink || false,
      boostLevel1: value.boostLevel1 || 0,
      boostLevel2: value.boostLevel2 || 0,
      boostLevel3: value.boostLevel3 || 0,
      defaultBoostLevel: value.defaultBoostLevel || null,
      intensity1: value.intensity1 || +value.dose[0],
      intensity2: value.intensity2 || +value.dose[1],
      intensity3: value.intensity3 || +value.dose[2],
      intensities: value.intensities || {
        ml: {
          V4: {
            intensity1: +value.intensities?.ml?.V4?.intensity1 || +value.intensities?.ml?.intensity1 || value.intensity1 || 0,
            intensity2: +value.intensities?.ml?.V4?.intensity2 || +value.intensities?.ml?.intensity2 || value.intensity2 || 0,
            intensity3: +value.intensities?.ml?.V4?.intensity3 || +value.intensities?.ml?.intensity3 || value.intensity3 || 0,
            boostLevel1: +value.intensities?.ml?.V4?.boostLevel1 || +value.intensities?.ml?.boostLevel1 || 0,
            boostLevel2: +value.intensities?.ml?.V4?.boostLevel2 || +value.intensities?.ml?.boostLevel2 || 0,
            boostLevel3: +value.intensities?.ml?.V4?.boostLevel3 || +value.intensities?.ml?.boostLevel3 || 0,
          },
          intensity1: +value.intensities?.ml?.intensity1 || 0,
          intensity2: +value.intensities?.ml?.intensity2 || 0,
          intensity3: +value.intensities?.ml?.intensity3 || 0,
          boostLevel1: +value.intensities?.ml?.boostLevel1 || 0,
          boostLevel2: +value.intensities?.ml?.boostLevel2 || 0,
          boostLevel3: +value.intensities?.ml?.boostLevel3 || 0,
          // intensity1: +value.intensities?.ml?.intensity1 || ((+value.intensity1 * 0.62) / 1000) || ((+value.dose[0] * 0.62) / 1000) || 0,
          // intensity2: +value.intensities?.ml?.intensity2 ||  ((+value.intensity2 * 0.62) / 1000) || ((+value.dose[1] * 0.62) / 1000) || 0,
          // intensity3: +value.intensities?.ml?.intensity3 ||  ((+value.intensity3 * 0.62) / 1000) || ((+value.dose[3] * 0.62) / 1000) || 0,
          // boostLevel1: +value.intensities?.ml?.boostLevel1 || ((+value.boostLevel1 * 0.62) / 1000) || 0,
          // boostLevel2: +value.intensities?.ml?.boostLevel2 || ((+value.boostLevel2 * 0.62) / 1000) || 0,
          // boostLevel3: +value.intensities?.ml?.boostLevel3 || ((+value.boostLevel3 * 0.62) / 1000) || 0
        },
        ms: {
            V4: {
                intensity1: +value.intensities?.ml?.V4?.intensity1 || +value.intensities?.ml?.intensity1 || 0,
                intensity2: +value.intensities?.ml?.V4?.intensity2 || +value.intensities?.ml?.intensity2 || 0,
                intensity3: +value.intensities?.ml?.V4?.intensity3 || +value.intensities?.ml?.intensity3 || 0,
                boostLevel1: +value.intensities?.ml?.V4?.boostLevel1 || +value.intensities?.ml?.boostLevel1 || 0,
                boostLevel2: +value.intensities?.ml?.V4?.boostLevel2 || +value.intensities?.ml?.boostLevel2 || 0,
                boostLevel3: +value.intensities?.ml?.V4?.boostLevel3 || +value.intensities?.ml?.boostLevel3 || 0,
          },
          intensity1:
            +value.intensities?.ms.intensity1 ||
            +value.intensity1 ||
            +value.dose[0],
          intensity2:
            +value.intensities?.ms.intensity2 ||
            +value.intensity2 ||
            +value.dose[1],
          intensity3:
            +value.intensities?.ms.intensity3 ||
            +value.intensity3 ||
            +value.dose[2],
          boostLevel1:
            value.intensities?.ms.boostLevel1 || +value.boostLevel1 || 0,
          boostLevel2:
            value.intensities?.ms.boostLevel2 || +value.boostLevel2 || 0,
          boostLevel3:
            value.intensities?.ms.boostLevel3 || +value.boostLevel3 || 0,
        },
      },
    },
    []
  );

  const options = [
    { key: 1, text: "Boost 1", value: 1 },
    { key: 2, text: "Boost 2", value: 2 },
    { key: 3, text: "Boost 3", value: 3 },
  ];
  const typeOptions = [
    { key: 1, text: "Main", value: "main" },
    { key: 2, text: "Substitute", value: "substitute" },
  ];

  useEffect(() => {
    if (state.inDrink || state.isBoost) {
      setDrinkItems(state);
    } else {
      removeFromDrink(state);
    }
  }, [state]);

  const onChangeDrink = (checked) => {

    setState((prevState) => {
      const newData = {
        ...prevState, 
        inDrink: checked,
        intensities: {
          ...prevState.intensities,
          [mode]: {
            ...prevState.intensities[mode],
            V4:{
                ...prevState.intensities[mode].V4,
                intensity1: prevState.intensities[mode].V4?.intensity1 || prevState.intensities[mode].intensity1 || 0,
                intensity2: prevState.intensities[mode].V4?.intensity2 || prevState.intensities[mode].intensity2 || 0,
                intensity3: prevState.intensities[mode].V4?.intensity3 || prevState.intensities[mode].intensity3 || 0,
                boostLevel1: prevState.intensities[mode].V4?.boostLevel1 || prevState.intensities[mode].boostLevel1 || 0,
                boostLevel2: prevState.intensities[mode].V4?.boostLevel2 || prevState.intensities[mode].boostLevel2 || 0,
                boostLevel3: prevState.intensities[mode].V4?.boostLevel3 || prevState.intensities[mode].boostLevel3 || 0,
            }
          },
        }
      };
      return newData
    });
  };

  const onChangeInput = (e) => {
    if(hw === 'V4') {
            setState((prevState) => {
              return {
                ...prevState,
                [e.target.name]: +e.target.value,
                intensities: {
                  ...prevState.intensities,
                  [mode]: {
                    ...prevState.intensities[mode],
                    V4:{
                        ...prevState.intensities[mode].V4,
                        [e.target.name]: e.target.value,
                    }
                  },
                },
              };
            });
            return
    }
    setState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: +e.target.value,
        intensities: {
          ...prevState.intensities,
          [mode]: {
            ...prevState.intensities[mode],
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  return (
    <Table.Row key={value._id}>
      <Table.Cell>
        <Header as="h4" image>
          <Header.Content>{`${
            value.name + "( " + value.volume + " ml)"
          }`}</Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell>
        <Header.Content>{value._id}</Header.Content>
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          clearable
          value={state.type}
          options={typeOptions}
          selection
          onChange={(e, { value }) => {
            setState((prevState) => {
              return { ...prevState, type: value };
            });
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <input
          style={{ ...StyleSheet.input }}
          type="text"
          name="rank"
          value={state.rank || index + 1}
          onChange={(e) => {
            onChangeInput(e);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          toggle
          defaultChecked={state.inDrink}
          onChange={(e, { checked }) => onChangeDrink(checked)}
        />
      </Table.Cell>
      <Table.Cell>
        <input
          disabled={!state.inDrink}
          style={{
            ...StyleSheet.input,
            borderColor: state.inDrink ? "lightblue" : "snow",
          }}
          type="text"
          name="intensity1"
          value={(()=>{
            if(hw === 'V4') {
                return (
                  state.intensities[mode]?.V4?.intensity1 ||
                  state.intensities[mode].intensity1 ||
                  0
                );
            }
            return state.intensities[mode].intensity1
          })()}
          onChange={(e) => {
            onChangeInput(e);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <input
          disabled={!state.inDrink}
          style={{
            ...StyleSheet.input,
            borderColor: state.inDrink ? "lightblue" : "snow",
          }}
          type="text"
          name="intensity2"
          value={(()=>{
            if(hw === 'V4') {
                return state.intensities[mode]?.V4?.intensity2 || state.intensities[mode].intensity2 || 0
            }
            return state.intensities[mode].intensity2
          })()}
          onChange={(e) => {
            onChangeInput(e);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <input
          disabled={!state.inDrink}
          style={{
            ...StyleSheet.input,
            borderColor: state.inDrink ? "lightblue" : "snow",
          }}
          type="text"
          name="intensity3"
          value={(()=>{
            if(hw === 'V4') {
                return state.intensities[mode].V4?.intensity3 || state.intensities[mode].intensity3 || 0
            }
            return state.intensities[mode].intensity3
          })()}
          onChange={(e) => {
            onChangeInput(e);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          toggle
          defaultChecked={state.isBoost}
          onChange={(e, { checked }) =>
            setState((prevState) => {
              const newData = { 
                ...prevState, 
                isBoost: checked,
                intensities: {
                  ...prevState.intensities,
                  [mode]: {
                    ...prevState.intensities[mode],
                    V4:{
                        ...prevState.intensities[mode].V4,
                        intensity1: prevState.intensities[mode].V4?.intensity1 || prevState.intensities[mode].intensity1 || 0,
                        intensity2: prevState.intensities[mode].V4?.intensity2 || prevState.intensities[mode].intensity2 || 0,
                        intensity3: prevState.intensities[mode].V4?.intensity3 || prevState.intensities[mode].intensity3 || 0,
                        boostLevel1: prevState.intensities[mode].V4?.boostLevel1 || prevState.intensities[mode].boostLevel1 || 0,
                        boostLevel2: prevState.intensities[mode].V4?.boostLevel2 || prevState.intensities[mode].boostLevel2 || 0,
                        boostLevel3: prevState.intensities[mode].V4?.boostLevel3 || prevState.intensities[mode].boostLevel3 || 0,
                    }
                  },
                },
                };
              return newData;
            })
          }
        />
      </Table.Cell>
      <Table.Cell>
        <input
          disabled={!state.isBoost}
          style={{
            ...StyleSheet.input,
            borderColor: state.isBoost ? "lightblue" : "snow",
          }}
          type="text"
          name="boostLevel1"
          value={(()=>{
            if(hw === 'V4') {
                return (
                  state.intensities[mode].V4?.boostLevel1 ||
                  state.intensities[mode].boostLevel1 || 0
                );
            }
            return state.intensities[mode].boostLevel1
          })()}
          onChange={(e) => {
            onChangeInput(e);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <input
          disabled={!state.isBoost}
          style={{
            ...StyleSheet.input,
            borderColor: state.isBoost ? "lightblue" : "snow",
          }}
          type="text"
          name="boostLevel2"
          value={(()=>{
            if(hw === 'V4') {
                return state.intensities[mode].V4?.boostLevel2 || state.intensities[mode].boostLevel2 || 0
            }
            return state.intensities[mode].boostLevel2
          })()}
          onChange={(e) => {
            onChangeInput(e);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <input
          disabled={!state.isBoost}
          style={{
            ...StyleSheet.input,
            borderColor: state.isBoost ? "lightblue" : "snow",
          }}
          type="text"
          name="boostLevel3"
          value={(()=>{
            if(hw === 'V4') {
                return state.intensities[mode].V4?.boostLevel3 || state.intensities[mode].boostLevel3 || 0
            }
            return state.intensities[mode].boostLevel3
          })()}
          onChange={(e) => {
            onChangeInput(e);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          clearable
          value={state.defaultBoostLevel}
          options={options}
          selection
          onChange={(e, { value }) => {
            setState((prevState) => {
              return { ...prevState, defaultBoostLevel: +value };
            });
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const StyleSheet = {
    input: {
        alignSelf: 'center',
        width: "70px",
        height: "30px",
        marginTop: '0px',
        marginLeft: '5px',
        borderStyle: "solid",
        borderRadius: "5px",
        borderWidth: "1px",
        borderColor: 'lightblue',
        backgroundColor: 'transparent'
    }
}

export default EditedDrinkIngredient
