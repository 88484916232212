import React from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { signInUser } from "../../redux/auth/auth.actions";

const LoginForm = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const { loading, error } = auth;

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    const { email, password } = data;
    dispatch(signInUser(email, password));
  };

  if (error) {
    toast.error(error);
  }

  return (
    <Form
      noValidate
      size="large"
      loading={loading}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Field>
        <label>Email</label>
        <input
          name="email"
          placeholder="your@example.com"
          ref={register({
            required: true,
            pattern: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
          })}
        />
        {errors.email && errors.email.type === "required" && (
          <p>Email is required</p>
        )}
        {errors.email && errors.email.type === "pattern" && (
          <p>Email is invalid</p>
        )}
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <input
          name="password"
          placeholder="At least 8 characters"
          type="password"
          icon="eye slash outline"
          ref={register({ required: true, minLength: 8, maxLength: 64})}
        />
        {errors.password && errors.password.type === "required" && (
          <p>Password is required</p>
        )}
        {errors.password && errors.password.type === "minLength" && (
          <p>Password should be at least 8 characters long</p>
        )}
        {errors.password && errors.password.type === "maxLength" && (
          <p>Password is too long</p>
        )}
      </Form.Field>
      <Button primary type="submit" fluid>
        Login
      </Button>
    </Form>
  );
};

export default LoginForm;
